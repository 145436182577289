import { styled } from "styled-components"

import { CHAT_TYPE } from "../constants"
import { getChatTypeSelectedMessage } from "../utils"

import ArtiMemberSelectField from "./ArtiMemberSelectField"
import { ArtiTourStepNames } from "./ArtiTour"

import View from "ui/View"

const ArtiMemberSelectorMessage = styled(function ArtiMemberSelectorMessage({
  user,
  team,
  llm,
  selectedMember,
  teamMembers,
  memberId,
  onMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
  showTeamSummary,
  chatTypeSelected,
  reviewType,
}) {
  return (
    <View $flexDirection="column" className="flex-shrink-0">
      {!!showTeamSummary &&
        (chatTypeSelected === CHAT_TYPE.TEAM_MEMBER || chatTypeSelected === CHAT_TYPE.PERFORMANCE_REVIEW) && (
          <View $flexDirection="column">
            <div className="mb-xxs text-semi-bold">
              <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
              <span className="ml-xxs">aRTi</span>
            </div>
            <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
              <div>{getChatTypeSelectedMessage({ llm, chatTypeSelected, reviewType })}</div>
            </View>
          </View>
        )}
      <div className="text-right mb-xxs text-semi-bold full-width">you</div>
      <div
        className="mb-medium pr-medium py-small message user full-width"
        data-arti-tour-step={ArtiTourStepNames.MemberSelector}
      >
        <ArtiMemberSelectField
          user={user}
          team={team}
          selectedMember={selectedMember}
          teamMembers={teamMembers}
          memberId={memberId}
          onMemberChange={onMemberChange}
          memberIdTeamNameMap={memberIdTeamNameMap}
          onStartNewConversation={onStartNewConversation}
          showTeamSummary={showTeamSummary}
        />
      </div>
    </View>
  )
})`
  width: 100%;
`

export default ArtiMemberSelectorMessage
