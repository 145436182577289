import "intro.js/introjs.css"

import Tour, { type TourProps } from "components/Tour"
import useWindowSize from "ui/hooks/useWindowSize"

enum ArtiTourStepNames {
  MemberSelector = "member-selector",
  QueryEditor = "query-editor",
  PopularTopics = "popular-topics",
  PreviousConversations = "previous-conversations",
  NewConversation = "new-conversation",
}

enum ArtiTourHintNames {
  MemberSelector = "member-selector",
  MemberSearch = "member-search",
}

const ARTI_TOUR_OPEN_POPULAR_TOPICS_STEP_INDICES = [1, 2]

const ArtiTour = ({
  introJsRef = null,
  autoStartTour = false,
  onTourStart = null,
  onStepChange = null,
  onTourExit = null,
}: Omit<TourProps, "steps" | "hash"> = {}) => {
  const { isMobileOrSmaller } = useWindowSize()
  return (
    <Tour
      introJsRef={introJsRef}
      autoStartTour={autoStartTour}
      onTourStart={onTourStart}
      onStepChange={onStepChange}
      onTourExit={onTourExit}
      steps={[
        {
          element: `[data-arti-tour-step=${ArtiTourStepNames.MemberSelector}]`,
          position: "top-middle-aligned",
          title: "How to get started",
          scrollToTop: true,
          hideButtons: true,
          disableStepper: true,
          disableInteraction: false, // we want user to be able to select a member here
          mobileFullWidth: true,
          intro: `
            Start by selecting a team member to discuss, or “N/A” for questions that
            don't involve a specific person. If you don't see the person you're
            looking for, use the search button to locate anyone associated with
            your&nbsp;account.
          `,
          hints: [`[data-arti-tour-hint=${ArtiTourHintNames.MemberSelector}] label:first-of-type`],
        },
        {
          element: `[data-arti-tour-step=${ArtiTourStepNames.QueryEditor}]`,
          position: isMobileOrSmaller ? "top-middle-aligned" : "top-right-aligned",
          title: "Ask aRTi",
          disableInteraction: true,
          mobileFullWidth: true,
          intro: `
            Type a question into the field or choose from the list of popular topics.
            When you're finished, press the arrow or use the shift + enter shortcut
            to submit your&nbsp;question.
          `,
        },
        {
          element: `[data-arti-tour-step=${ArtiTourStepNames.PopularTopics}]`,
          position: "top-middle-aligned",
          title: "Not sure what to ask?",
          disableInteraction: true,
          mobileFullWidth: true,
          intro: `
            Use the “Popular&nbsp;topics” bar to find prompts to get the conversation
            started. These prompts can also be edited before you ask aRTi to ensure
            they’re as relevant as you need them to&nbsp;be.
          `,
        },
        {
          element: `[data-arti-tour-step=${ArtiTourStepNames.PreviousConversations}]`,
          position: "bottom-middle-aligned",
          title: "Previous conversations",
          scrollToTop: true,
          disableInteraction: true,
          mobileFullWidth: true,
          intro: `
            If you’d like to reflect on a past discussion, this drop down allows you to find
            them or start typing to&nbsp;search.
          `,
        },
        {
          element: `[data-arti-tour-step=${ArtiTourStepNames.NewConversation}]`,
          position: "bottom-middle-aligned",
          title: "Start fresh",
          disableInteraction: true,
          intro: `
            When you’re ready to talk about someone else or need to start over,
            the “New&nbsp;conversation” button can be used at any&nbsp;time.
          `,
        },
      ]}
    />
  )
}

export default ArtiTour
export { ArtiTourStepNames, ArtiTourHintNames, ARTI_TOUR_OPEN_POPULAR_TOPICS_STEP_INDICES }
