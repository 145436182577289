import "intro.js/introjs.css"

import Tour, { type TourProps } from "components/Tour"

enum KitFiltersTourStepNames {
  FilterControls = "filter-controls",
}

const KitFiltersTour = ({
  introJsRef = null,
  autoStartTour = false,
  onTourStart = null,
  onStepChange = null,
  onTourExit = null,
}: Omit<TourProps, "steps" | "hash"> = {}) => (
  <Tour
    introJsRef={introJsRef}
    autoStartTour={autoStartTour}
    onTourStart={onTourStart}
    onStepChange={onStepChange}
    onTourExit={onTourExit}
    doneLabel="Start exploring"
    steps={[
      {
        element: `[data-kit-filters-tour-step=${KitFiltersTourStepNames.FilterControls}]`,
        position: "top-middle-aligned",
        title: "Find the perfect kit",
        hideStepper: true,
        mobileFullWidth: true,
        intro: `
          Now you can explore kits by <b>session length</b>, <b>status</b>,
          and our all&#8209;new <b style="color: var(--orange-3)">⬥Goals</b>,
          so you can choose kits by what you want your team to get from the session.
          <br><br>
        `,
      },
    ]}
  />
)

export default KitFiltersTour
export { KitFiltersTourStepNames }
