import { useQueryClient } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router"
import { styled } from "styled-components"

import { ArtiSender, LLMType, NO_ONE_MEMBER, VANILLA_LLM_TYPES, CHAT_TYPE } from "../constants"
import {
  getHistorySession,
  prependHistorySessionExchangeToQueryCache,
  preprocessArtiMemberData,
  preprocessArtiTeamData,
  sendAndProcessArtiChat,
  sendAndProcessTeamArtiChat,
  sendAndProcessArtiUpload,
  sendAndProcessPerformanceReviewChat,
  updateExcludeFromManualReview,
  useArtiHistorySessionExchanges,
  useArtiHistoryTopExchanges,
  useArtiTeams,
} from "../resource"
import {
  availableLLMsForUser,
  getMemberSelectedMessage,
  getTeamSelectedMessage,
  createMembersAndTeamMap,
  isValidMessageText,
} from "../utils"

import ArtiChatTypePerformanceReview from "./ArtiChatTypePerformanceReview"
import ArtiChatTypeSelectorMessage from "./ArtiChatTypeSelectorMessage"
import ArtiChatTypeTeam from "./ArtiChatTypeTeam"
import ArtiChatTypeTeamMember from "./ArtiChatTypeTeamMember"
import ArtiFooterDescription from "./ArtiFooterDescription"
import ArtiIntro from "./ArtiIntro"
import ArtiPreviousConversationsSelect from "./ArtiPreviousConversationsSelect"
import ArtiTour, { ARTI_TOUR_OPEN_POPULAR_TOPICS_STEP_INDICES, ArtiTourStepNames } from "./ArtiTour"

import { useScrollToTopContext } from "components/ScrollToTop"
import ArtiChatHeader from "domains/Arti/components/ArtiChatHeader"
import { Choice } from "forms/fields/ChoicesField"
import handleErrors from "forms/handleErrors"
import { CalloutName, useCalloutStatus, useUpdateCalloutStatus } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"
import { isProductionEnv } from "utils/env"
import { useHasTeamFeature } from "utils/team"

const ArtiChat = styled(function ArtiChat({ className, user, team }) {
  const chatInputRef = useRef()
  const { state } = useLocation()
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { setNoScrollToTopForPaths, resetNoScrollToTop } = useScrollToTopContext()
  useEffectAfterFirstRender(() => () => resetNoScrollToTop()) // reset when component unmounts

  const { enabled: showTeamSummary, isInitialLoading: showTeamSummaryLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_ARTI_TEAM_SUMMARY
  )
  const { enabled: allowArtiUpload } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_ARTI_ALLOW_UPLOAD)

  const { enabled: doNotUseStreaming } = useHasTeamFeature(team, SHARED_FLAGS.ARTI_DO_NOT_USE_STREAMING)

  const showChatHistory = !user.is_demo_mode_active
  const showExcludeFromManualReview = !!user.is_staff && !user.is_demo_mode_active

  const artiTourIntroJsRef = useRef()
  const [artiTourPopularTopicsMenuOpen, setArtiTourForcePopularTopicsMenuOpen] = useState(false)
  const calloutName = CalloutName.ARTI_TOUR
  const {
    data: hasSeenArtiTour,
    isFetching: isFetchingHasSeenArtiTour,
    isError: isErrorHasSeenArtiTour,
  } = useCalloutStatus({
    userId: user.id,
    calloutName,
  })
  const { mutateAsync: updateArtiTourStatus } = useUpdateCalloutStatus({ userId: user.id, calloutName })

  const { data: teams } = useArtiTeams()
  const allNonJumboTeams = (teams ?? []).filter(({ jumbo }) => !jumbo)

  const [messages, setMessages] = useState([])
  const [memberId, setMemberId] = useState(state?.memberId)
  const [discussionTeamId, setDiscussionTeamId] = useState(state?.discussionTeamId)
  const [loading, setLoading] = useState(false)
  const defaultLLM = isProductionEnv() ? LLMType.GPT_4o : LLMType.GPT_3_5
  const [llm, setLLM] = useState(defaultLLM)
  const [sessionStartedAt, setSessionStartedAt] = useState(null)
  const [startExchangeId, setStartExchangeId] = useState(null)
  const [prevExchangeId, setPrevExchangeId] = useState(null)
  const [latestExchangeId, setLatestExchangeId] = useState(null)
  const [excludeFromManualReview, setExcludeFromManualReview] = useState(false)
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false)
  const { testId } = useQueryParams()
  const availableLLMs = availableLLMsForUser(user)
  const isDefaultLLM = llm === defaultLLM
  const [chatTypeSelected, setChatTypeSelected] = useState(state?.chatTypeSelected)
  const [reviewType, setReviewType] = useState(state?.reviewType)

  // TODO: @shivam-risingteam - Once we add logic for conversation-helper, change the init state
  const [isArtiUploadAvailable, setIsArtiUploadAvailable] = useState(false)

  const { id: historyExchangeIdUrlParamValue } = useParams()
  const historyExchangeId = Number.isNaN(parseInt(historyExchangeIdUrlParamValue))
    ? null
    : parseInt(historyExchangeIdUrlParamValue)

  const { data: historySessionExchanges, isFetching: isFetchingHistorySession } = useArtiHistorySessionExchanges(
    historyExchangeId,
    { enabled: !hasLoadedHistory }
  )
  const { data: historyExchanges, isFetching: isFetchingHistoryExchanges } = useArtiHistoryTopExchanges({
    enabled: !!showChatHistory,
  })

  const { memberIdTeamNameMap, teamMembers } = createMembersAndTeamMap({ allNonJumboTeams, team, user })

  const selectedMember = teamMembers.find(({ id }) => id === memberId)
  const selectedDiscussionTeam = teams?.find(({ id }) => id === discussionTeamId)
  const hasMessagesBeyondMemberSelected = chatTypeSelected === CHAT_TYPE.TEAM_MEMBER && messages.length > 1
  const hasMessagesBeyondTeamSelected = chatTypeSelected === CHAT_TYPE.TEAM && messages.length > 1

  // Update member selected message if that's the only message:
  useEffectAfterChange(() => {
    if (!hasMessagesBeyondMemberSelected && chatTypeSelected === CHAT_TYPE.TEAM_MEMBER) {
      setMessages([
        getMemberSelectedMessage({ user, teamMember: selectedMember, chatTypeSelected, reviewType, llm, team }),
      ])
    }
  }, [user, team, llm, selectedMember, hasMessagesBeyondMemberSelected, chatTypeSelected, reviewType])

  // Update selectedMember if LLM is a vanilla type and there is no current conversation:
  useEffectAfterChange(() => {
    if (
      !hasMessagesBeyondMemberSelected &&
      chatTypeSelected === CHAT_TYPE.TEAM_MEMBER &&
      VANILLA_LLM_TYPES.includes(llm)
    ) {
      setMemberId(NO_ONE_MEMBER.id)
    }
  }, [llm, hasMessagesBeyondMemberSelected, chatTypeSelected])

  useEffectAfterChange(() => {
    if (!hasMessagesBeyondTeamSelected && chatTypeSelected === CHAT_TYPE.TEAM) {
      setMessages([getTeamSelectedMessage({ llm, user, team: selectedDiscussionTeam, chatTypeSelected })])
    }
  }, [user, selectedDiscussionTeam, llm, hasMessagesBeyondTeamSelected, chatTypeSelected])

  // Manage loading and rendering of history exchange (if selected):
  useEffectAfterChange(() => {
    const session = getHistorySession(historySessionExchanges)

    if (historyExchangeId && session.teamId && session.teamId !== team.id) {
      navigate(`/arti/${historyExchangeId}?team_id=${session.teamId}${window.location.hash}`, {
        replace: true,
      })
      return
    }

    if (hasLoadedHistory || !team || !user || !teams) {
      return
    }

    if (!historyExchangeId) {
      setHasLoadedHistory(true)
      return
    }

    if (session.messages.length && latestExchangeId !== historyExchangeId) {
      if (!!session.isTeamExchange) {
        const sessionTeamId = session.teamId
        const team = teams.find(({ id }) => id === sessionTeamId)
        setMessages([
          getTeamSelectedMessage({ user, llm: session.llm, team, chatTypeSelected: CHAT_TYPE.TEAM }),
          ...session.messages,
        ])
        setChatTypeSelected(CHAT_TYPE.TEAM)
        setDiscussionTeamId(session.teamId)
        preprocessArtiTeamData(session.teamId)
      } else {
        const sessionMemberId = session.memberId ?? NO_ONE_MEMBER.id
        const teamMember =
          teams?.flatMap(({ members }) => members)?.find(({ id }) => id === sessionMemberId) ?? NO_ONE_MEMBER

        // TODO(arti) Instead of building a new member selected message for this, we should store
        // TODO member selected messages with exchange records on backend
        setMessages([
          getMemberSelectedMessage({
            user,
            teamMember,
            llm: session.llm,
            chatTypeSelected,
            reviewType,
            team,
          }),
          ...session.messages,
        ])
        setMemberId(sessionMemberId)
        preprocessArtiMemberData(team.id, sessionMemberId)
      }

      setStartExchangeId(session.firstExchangeId)
      setLatestExchangeId(session.lastExchangeId)
      setPrevExchangeId(session.lastExchangeId)
      setExcludeFromManualReview(session.excludeFromManualReview)

      if (session.llm && Object.values(availableLLMs).includes(session.llm)) {
        setLLM(session.llm)
        updatePersistedValue(llmPersistenceKey, session.llm, { defaultValue: defaultLLM })
      }
      setHasLoadedHistory(true)
    }
  }, [
    user,
    team,
    teams,
    latestExchangeId,
    historyExchangeId,
    historySessionExchanges,
    navigate,
    availableLLMs,
    defaultLLM,
    hasLoadedHistory,
    chatTypeSelected,
    reviewType,
  ])

  const llmPersistenceKey = "RISINGTEAM_ARTI_LLM"

  // On initial render, load values from session storage.
  // If a value is invalid or matches default, clear it from session storage.
  function loadPersistedValue(key, { defaultValue = null, isValid, setValue }) {
    const value = window.sessionStorage.getItem(key)
    const isInvalid = isValid(value)
    const isDefault = value === defaultValue
    if (isInvalid || isDefault) {
      window.sessionStorage.removeItem(key)
    } else {
      setValue(value)
    }
  }
  function updatePersistedValue(key, value, { defaultValue } = {}) {
    if (value === defaultValue) {
      window.sessionStorage.removeItem(key)
    } else {
      window.sessionStorage.setItem(key, value)
    }
  }
  useEffectAfterFirstRender(() =>
    loadPersistedValue(llmPersistenceKey, {
      defaultValue: defaultLLM,
      isValid: (value) => !Object.values(availableLLMs).includes(value),
      setValue: (value) => setLLM(value),
    })
  )

  function onChatTypeChange({ value }) {
    setChatTypeSelected(value)
    navigate(`/arti?chat_type=${value}${window.location.hash}`, {
      replace: true,
      state: { ...state, chatTypeSelected: value },
    })
  }

  function onMemberChange({ value }) {
    const teamMemberId = parseInt(value)
    const teamMember = teamMembers.find(({ id }) => id === teamMemberId)
    setMessages([getMemberSelectedMessage({ user, teamMember, chatTypeSelected, reviewType, llm, team })])
    setMemberId(teamMemberId)
    setSessionStartedAt(null)
    setStartExchangeId(null)
    setPrevExchangeId(null)
    setLatestExchangeId(null)
    setExcludeFromManualReview(false)

    const isMemberOfCurrentTeam = !!team.members.find(({ id }) => id === teamMemberId)
    const memberTeam = isMemberOfCurrentTeam
      ? team
      : (allNonJumboTeams.find(({ members }) => members.find(({ id }) => id === teamMemberId)) ?? team)

    preprocessArtiMemberData(memberTeam.id, teamMemberId)

    navigate(`/arti?team_id=${memberTeam.id}${window.location.hash}`, {
      replace: true,
      state: { ...state, memberId: teamMemberId },
    })
    chatInputRef.current?.focus()
    chatInputRef.current?.scrollIntoView()

    if (artiTourIntroJsRef.current) {
      artiTourIntroJsRef.current.nextStep()
    }
  }

  function onDiscussionTeamChange({ value }) {
    const discussionTeamId = parseInt(value)
    const discussionTeam = teams.find(({ id }) => id === discussionTeamId)
    setMessages([getTeamSelectedMessage({ llm, user, discussionTeam, chatTypeSelected })])
    setDiscussionTeamId(discussionTeamId)
    setSessionStartedAt(null)
    setStartExchangeId(null)
    setPrevExchangeId(null)
    setLatestExchangeId(null)
    setExcludeFromManualReview(false)
    preprocessArtiTeamData(discussionTeamId)

    // Update url to remove current ArtiExchange ID and set correct team ID:
    navigate(`/arti?team_id=${discussionTeamId}${window.location.hash}`, {
      replace: true,
      state: { ...state, discussionTeamId },
    })
    chatInputRef.current?.focus()
    chatInputRef.current?.scrollIntoView()
  }

  function onLLMChange({ value }) {
    // Update current LLM value, and save to session storage if not the default.
    setLLM(value)
    updatePersistedValue(llmPersistenceKey, value, { defaultValue: defaultLLM })
  }

  const [isComponentMounted, setIsComponentMounted] = useState(true)
  useEffectAfterFirstRender(() => () => setIsComponentMounted(false))

  function startNewConversation() {
    setMessages([])
    setMemberId(null)
    setDiscussionTeamId(null)
    setChatTypeSelected(null)
    setReviewType(null)
    setSessionStartedAt(null)
    setStartExchangeId(null)
    setPrevExchangeId(null)
    setLatestExchangeId(null)
    setExcludeFromManualReview(false)
    navigate(`/arti?team_id=${team.id}${window.location.hash}`)
  }

  function onHistoryExchangeSelect({ value, formik }) {
    requestAnimationFrame(() => isComponentMounted && formik.resetForm())
    // We reset form here because we don't want the select to actually
    // show the value; we just load the conversation and leave the
    // select text as-is. The reset won't work without waiting a frame.

    // Avoid router default "scroll to top" behavior for following navigation:
    setNoScrollToTopForPaths([latestExchangeId ? `/arti/${latestExchangeId}` : "/arti", `/arti/${value}`])
    setHasLoadedHistory(false)

    if (!historyExchangeId && latestExchangeId) {
      // If previous exchange wasn't represented by ID in URL, add a browser history
      // entry for it (w/ replace=true) so browser-back may nav back to it if desired.
      navigate(`/arti/${latestExchangeId}?team_id=${team.id}${window.location.hash}`, { replace: true })
    }
    // Navigate to URL for selected history exchange:
    navigate(`/arti/${value}?team_id=${team.id}${window.location.hash}`)
  }

  function onExcludeFromManualReviewChange(event) {
    const newValue = event.target.checked
    setExcludeFromManualReview(newValue)
    updateExcludeFromManualReview(startExchangeId, newValue)
  }

  const onSubmit = handleErrors(async (values, { resetForm }) => {
    if ((!selectedMember && !selectedDiscussionTeam) || loading || !isValidMessageText(values?.message)) {
      // if a request is already in flight, or message is blank, ignore this submit
      return false
    } else {
      setLoading(true)
    }

    const newUserMessage = { sender: ArtiSender.USER, text: values.message }
    const newMessages = [...messages, newUserMessage]
    const uploadFileName = values?.fileName

    const updateNewBotMessage = ({ text, exchangeId }) => {
      const newBotMessage = { sender: ArtiSender.BOT, text, exchangeId }
      setMessages([...newMessages, newBotMessage])
    }

    updateNewBotMessage({ text: "" }) // show empty bot response with loading icon
    resetForm()

    const artiData =
      !!allowArtiUpload && !!uploadFileName
        ? await sendAndProcessArtiUpload({
            team,
            selectedMember,
            newUserMessage,
            fileName: uploadFileName,
            updateNewBotMessage,
            useStreaming: !doNotUseStreaming,
            artiChatParams: {
              llm,
              sessionStartedAt,
              startExchangeId,
              prevExchangeId,
              testId,
              chatTypeSelected,
            },
          })
        : !!showTeamSummary && chatTypeSelected === CHAT_TYPE.TEAM
          ? await sendAndProcessTeamArtiChat({
              team: selectedDiscussionTeam,
              newUserMessage,
              updateNewBotMessage,
              useStreaming: !doNotUseStreaming,
              artiChatParams: {
                llm,
                sessionStartedAt,
                startExchangeId,
                prevExchangeId,
                testId,
                chatTypeSelected,
              },
            })
          : !!showTeamSummary && chatTypeSelected === CHAT_TYPE.PERFORMANCE_REVIEW
            ? await sendAndProcessPerformanceReviewChat({
                team,
                selectedMember,
                newUserMessage,
                updateNewBotMessage,
                useStreaming: !doNotUseStreaming,
                artiChatParams: {
                  llm,
                  sessionStartedAt,
                  startExchangeId,
                  prevExchangeId,
                  testId,
                  chatTypeSelected,
                },
              })
            : await sendAndProcessArtiChat({
                team,
                selectedMember,
                newUserMessage,
                updateNewBotMessage,
                useStreaming: !doNotUseStreaming,
                artiChatParams: {
                  llm,
                  sessionStartedAt,
                  startExchangeId,
                  prevExchangeId,
                  testId,
                  chatTypeSelected,
                },
              })

    const artiExchange = artiData.arti_exchange

    setLatestExchangeId(artiExchange?.id ?? null)

    if (artiExchange) {
      setSessionStartedAt(artiExchange.session_started_at ?? null)
      setStartExchangeId(artiExchange.start_exchange_id ?? null)
      setPrevExchangeId(artiExchange.id ?? null)
      prependHistorySessionExchangeToQueryCache(
        queryClient,
        { ...artiExchange, query: values.message }
        // must add query to exchange here since we only get basic exchange from API
      )

      // Avoid router default "scroll to top" behavior for following navigation:
      setNoScrollToTopForPaths([
        latestExchangeId ? `/arti/${latestExchangeId}` : "/arti",
        `/arti/${artiExchange.id}`,
        `/arti/${historyExchangeId}`,
      ])

      // Update URL to match new exchange ID, so tab can be refreshed, duplicated, etc:
      navigate(`/arti/${artiExchange.id}?team_id=${team.id}${window.location.hash}`, { replace: true, state })
    }

    setLoading(false)
  })

  return (
    <View className={className}>
      <ArtiTour
        introJsRef={artiTourIntroJsRef}
        autoStartTour={!isFetchingHasSeenArtiTour && !isErrorHasSeenArtiTour && !hasSeenArtiTour}
        onTourStart={() => updateArtiTourStatus({ hasViewedCallout: true })}
        onStepChange={(newStepIndex) => {
          if (newStepIndex === 0) {
            startNewConversation()
          }
          setArtiTourForcePopularTopicsMenuOpen(ARTI_TOUR_OPEN_POPULAR_TOPICS_STEP_INDICES.includes(newStepIndex))
        }}
        onTourExit={() => setArtiTourForcePopularTopicsMenuOpen(false)}
      />
      <div className="main-container full-width neg-mt-large">
        <ArtiChatHeader
          onLLMChange={onLLMChange}
          llm={llm}
          availableLLMs={availableLLMs}
          team={team}
          startNewConversation={startNewConversation}
          chatTypeSelected={chatTypeSelected}
        />
        {!!showChatHistory && (
          <Formik initialValues={{ exchangeId: null }}>
            {(formik) => (
              <Form className="mt-xs mb-xl-mobile-never mb-xs-mobile-only">
                <div data-arti-tour-step={ArtiTourStepNames.PreviousConversations}>
                  <ArtiPreviousConversationsSelect
                    name="exchangeId"
                    width="100%"
                    alignRight
                    showConversationName
                    user={user}
                    teamMembers={teamMembers}
                    teams={teams}
                    formik={formik}
                    historyExchanges={historyExchanges}
                    isLoading={isFetchingHistoryExchanges}
                    placeholder={
                      isFetchingHistoryExchanges
                        ? "Loading previous conversations..."
                        : isMobileLandscapeOrSmaller
                          ? "Previous conversations"
                          : "Type to search previous conversations"
                    }
                    onSelect={onHistoryExchangeSelect}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
        {!showTeamSummaryLoading && (
          <>
            <ArtiIntro user={user} showTeamSummary={showTeamSummary} />
            {!showTeamSummary ? (
              <ArtiChatTypeTeamMember
                user={user}
                team={team}
                llm={llm}
                selectedMember={selectedMember}
                teamMembers={teamMembers}
                memberId={memberId}
                onMemberChange={onMemberChange}
                memberIdTeamNameMap={memberIdTeamNameMap}
                onStartNewConversation={startNewConversation}
                showTeamSummary={showTeamSummary}
                chatTypeSelected={chatTypeSelected}
                reviewType={reviewType}
                isFetchingHistorySession={isFetchingHistorySession}
                messages={messages}
                loading={loading}
                historyExchangeId={historyExchangeId}
                chatInputRef={chatInputRef}
                artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
                isComponentMounted={isComponentMounted}
                onSubmit={onSubmit}
              />
            ) : (
              <>
                <ArtiChatTypeSelectorMessage
                  onChange={onChatTypeChange}
                  chatTypeSelected={chatTypeSelected}
                  onStartNewConversation={startNewConversation}
                  team={team}
                />
                {chatTypeSelected === CHAT_TYPE.TEAM_MEMBER && (
                  <ArtiChatTypeTeamMember
                    user={user}
                    team={team}
                    llm={llm}
                    selectedMember={selectedMember}
                    teamMembers={teamMembers}
                    memberId={memberId}
                    onMemberChange={onMemberChange}
                    memberIdTeamNameMap={memberIdTeamNameMap}
                    onStartNewConversation={startNewConversation}
                    showTeamSummary={showTeamSummary}
                    chatTypeSelected={chatTypeSelected}
                    reviewType={reviewType}
                    isFetchingHistorySession={isFetchingHistorySession}
                    messages={messages}
                    loading={loading}
                    historyExchangeId={historyExchangeId}
                    chatInputRef={chatInputRef}
                    artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
                    isComponentMounted={isComponentMounted}
                    onSubmit={onSubmit}
                  />
                )}
                {chatTypeSelected === CHAT_TYPE.PERFORMANCE_REVIEW && (
                  <ArtiChatTypePerformanceReview
                    user={user}
                    team={team}
                    llm={llm}
                    selectedMember={selectedMember}
                    teamMembers={teamMembers}
                    memberId={memberId}
                    onMemberChange={onMemberChange}
                    memberIdTeamNameMap={memberIdTeamNameMap}
                    onStartNewConversation={startNewConversation}
                    showTeamSummary={showTeamSummary}
                    chatTypeSelected={chatTypeSelected}
                    isFetchingHistorySession={isFetchingHistorySession}
                    messages={messages}
                    loading={loading}
                    historyExchangeId={historyExchangeId}
                    chatInputRef={chatInputRef}
                    artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
                    isComponentMounted={isComponentMounted}
                    onSubmit={onSubmit}
                    allowArtiUpload={allowArtiUpload}
                    isArtiUploadAvailable={isArtiUploadAvailable}
                    setIsArtiUploadAvailable={setIsArtiUploadAvailable}
                  />
                )}
                {chatTypeSelected === CHAT_TYPE.TEAM && (
                  <ArtiChatTypeTeam
                    user={user}
                    team={team}
                    teams={teams}
                    llm={llm}
                    selectedDiscussionTeam={selectedDiscussionTeam}
                    onDiscussionTeamChange={onDiscussionTeamChange}
                    showTeamSummary={showTeamSummary}
                    chatTypeSelected={chatTypeSelected}
                    discussionTeamId={discussionTeamId}
                    isFetchingHistorySession={isFetchingHistorySession}
                    messages={messages}
                    loading={loading}
                    historyExchangeId={historyExchangeId}
                    chatInputRef={chatInputRef}
                    artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
                    isComponentMounted={isComponentMounted}
                    onSubmit={onSubmit}
                  />
                )}
                {/* TODO (arti): Restore when moving "me" and "something else" options */}
                {/* {chatTypeSelected === CHAT_TYPE.ME && <div>Me selected.</div>}
                {chatTypeSelected === CHAT_TYPE.OTHER && <div>Other selected.</div>} */}
              </>
            )}
          </>
        )}
        <ArtiFooterDescription llm={llm} isDefaultLLM={isDefaultLLM} />
        {!!showExcludeFromManualReview && !!startExchangeId && (
          <div className="mt-small">
            <Choice
              type="checkbox"
              label="Exclude conversation from manual review (internal use only)"
              onChange={onExcludeFromManualReviewChange}
              checked={excludeFromManualReview}
            />
          </div>
        )}
      </div>
    </View>
  )
})`
  .conversation-container.hidden {
    height: 0;
  }

  // Message css styles start
  // Need to keep this here because a lot of the styles
  // in child components are dependent on these classes
  .message {
    position: relative;
    color: var(--white);
    font-weight: 600;
    border-radius: 8px;

    /* nubbin */
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      height: 0.5rem;
      width: 1rem;
      border-radius: 3px 3px 0 0;
      z-index: var(--z-above-zero);
    }
  }

  .message.user {
    background-color: var(--orange-4);
    text-shadow: var(--blur-4);
    font-weight: 500;

    /* nubbin */
    &::before {
      right: 1rem;
      background-color: var(--orange-4);
      transform: skew(0, -30deg);
      transform-origin: 100% 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
    }
  }

  .message.bot {
    color: var(--default);
    font-weight: 400;

    background-color: var(--gray-1);
    border: 1px solid var(--gray-4);
    box-shadow: var(--blur-4);

    /* nubbin */
    &::before {
      left: 1rem;
      background-color: var(--gray-1);
      border: 1px solid var(--gray-4);
      border-bottom: none;
      padding-bottom: 1px;
      box-shadow: var(--blur-4);
      transform: skew(0, 30deg);
      transform-origin: 0 0;
      clip-path: polygon(-10px -10px, 35px -10px, -10px 16px);
    }

    .icon-buttons {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: flex-end;
      float: right;
      color: var(--gray-7);
      width: fit-content;
      z-index: var(--z-below-sticky);
      // z-index needed to allow flag and copy buttons to be interactive
    }
  }
  // Message css styles end

  // Chat area type start
  // This is needed for the chat content
  li {
    margin-left: var(--spacing-4);
  }

  table {
    width: 100%;
    border-spacing: 0; // no white lines between table cells
    margin-bottom: 12px;
  }

  tr {
    height: var(--row-height);
  }

  th {
    padding: 8px 12px;

    &.shrink {
      width: 1px; // make column "shrink" to minimum width to contain header text
      padding-right: 15px; // adjust to ensure header text remains centered
    }
  }

  td {
    padding: 4px 12px;
  }

  th,
  td {
    text-align: left;
    position: relative;
  }
`

export default ArtiChat
